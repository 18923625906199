import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 519;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalDialog = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
`;

export const ModalBodyWrapper = styled.div`
  margin: -2rem;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;
