import React from 'react';
import { Icon } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';

import { SidebarButton } from '../styles';

type ButtonProps = {
  title: string;
  onClick(): void;
};

export const Button = ({ title, onClick }: ButtonProps) => {
  return (
    <SidebarButton onClick={onClick} style={{ padding: '20px' }}>
      <div style={{ position: 'absolute' }}>
        <Icon source={ChevronLeftMinor} />
      </div>
      <span
        style={{
          width: '100%',
          fontSize: '15px',
          textAlign: 'center',
        }}
      >
        {title}
      </span>
    </SidebarButton>
  );
};
