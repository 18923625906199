import update from 'immutability-helper';
import * as R from 'ramda';

import type { Action, State } from './types';
import { ActionType } from './types';

export function addProduct(state: State, action: Action) {
  const selected = R.append(action.payload, state.selected);

  if (state.selectMultiple) {
    return {
      ...state,
      selected,
      productSelected: selected.length,
      selectDisabled: state.maxSelectedProducts <= selected.length,
    };
  }

  if (!state.selectMultiple && selected.length > 0) {
    return {
      ...state,
      selected,
      productSelected: selected.length,
      selectDisabled: true,
    };
  }

  return state;
}

export function updateProduct(state: State, action: Action) {
  const { selected } = state;
  const { id: productId } = action.payload;
  const index = selected.findIndex(({ id }: any) => id === productId);
  return {
    ...state,
    selected: R.update(index, action.payload, state.selected),
  };
}

export function removeProduct(state: State, action: any) {
  const selected = R.reject(R.propEq('id', action.payload), state.selected);

  return {
    ...state,
    selected,
    selectDisabled: state.selectMultiple
      ? state.maxSelectedProducts <= selected.length
      : selected.length !== 0,
  };
}

export function addVariant(state: State, action: Action): State {
  const { product, ...variant } = action.payload;
  const currentProduct = state.selected.find(({ id }) => id === product.id);

  if (currentProduct) {
    const newProduct = {
      ...currentProduct,
      variants: R.append(variant, currentProduct.variants),
      variantIds: R.append(variant.id, currentProduct.variantIds),
    };
    const index = R.findIndex(R.propEq('id', product.id), state.selected);
    const selected = R.update(index, newProduct, state.selected);
    return { ...state, selected };
  }

  return addProduct(state, {
    type: ActionType.ADD_PRODUCT,
    payload: {
      ...action.payload.product,
      variantIds: [variant.id],
      variants: [variant],
    },
  });
}

export function removeProductVariant(state: State, action: Action) {
  const { product, ...variant } = action.payload;
  const index = state.selected.findIndex(({ id }: any) => id === product.id);
  const currentProduct = state.selected[index];

  if (currentProduct) {
    const newVariantIds = currentProduct.variantIds.filter(
      (variantId: number) => variantId !== variant.id,
    );
    const newVariants = currentProduct.variants.filter(
      ({ id }) => id !== variant.id,
    );

    if (newVariantIds.length) {
      const selected = update(state.selected, {
        [index]: {
          $set: {
            ...currentProduct,
            variantIds: newVariantIds,
            variants: newVariants,
          },
        },
      });
      return { ...state, selected };
    }

    const selected = state.selected.filter(({ id }) => product.id !== id);
    return { ...state, selected, selectDisabled: !!newVariantIds.length };
  }
  return state;
}
