import { Card, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

export const HeroSkeleton = () => {
  return (
    <Card>
      <div style={{ height: 360 }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flex: '0 0 50%', padding: '5rem' }}>
            <div style={{ marginBottom: '3rem' }}>
              <SkeletonDisplayText size="large" />
            </div>
            <div style={{ maxWidth: '50rem' }}>
              <SkeletonBodyText />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
