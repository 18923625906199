import React, { useContext, useMemo, useReducer } from 'react';

export enum ActionType {
  OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL',
  SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES',
  LEAVE_PAGE = 'LEAVE_PAGE',
}

export type Action = { type: ActionType; payload: any };
export type Dispatch = (action: Action) => void;

interface State {
  isUnsavedChanges: boolean;
  openConfirmModal: boolean;
  route: any | null;
}

type SaveBarContext = {
  state: State;
  dispatch: Dispatch;
};

const SaveBarContext = React.createContext<SaveBarContext | undefined>(
  undefined,
);

type SaveBarProvideProps = {
  children: React.ReactNode;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.OPEN_CONFIRM_MODAL:
      return { ...state, openConfirmModal: true, route: action.payload };
    case ActionType.CLOSE_CONFIRM_MODAL:
      return { ...state, openConfirmModal: false, route: null };
    case ActionType.LEAVE_PAGE:
      return {
        ...state,
        openConfirmModal: false,
        isUnsavedChanges: false,
        route: null,
      };
    case ActionType.SET_UNSAVED_CHANGES:
      return { ...state, isUnsavedChanges: action.payload };
    default:
      return state;
  }
};

export const ContextualSaveBarProvider = (props: SaveBarProvideProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, {
    isUnsavedChanges: false,
    openConfirmModal: false,
    route: null,
  });

  const values = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <SaveBarContext.Provider value={values}>{children}</SaveBarContext.Provider>
  );
};

export function useContextualSaveBarContext() {
  const context = useContext(SaveBarContext);

  if (context === undefined) {
    throw new Error(`
      useContextualSaveBarContext must be used within a 
      ContextualSaveBarProvider
    `);
  }

  return context;
}
