import React from 'react';
import { DisplayText } from '@shopify/polaris';

type DescriptionProps = {
  children: React.ReactNode;
};

export const Description = ({ children }: DescriptionProps) => {
  return <DisplayText element="p">{children}</DisplayText>;
};
