import React from 'react';
import { TextField } from '@shopify/polaris';
import styled from 'styled-components';

type SearchProps = {
  value: string;
  onChange(value: string): void;
  onClear(): void;
};

const Wrapper = styled.div`
  position: relative;
`;

export const Search = ({ value, onChange, onClear }: SearchProps) => {
  return (
    <Wrapper>
      <TextField
        labelHidden
        clearButton
        label="Search product"
        placeholder="Search product"
        value={value}
        onChange={onChange}
        onClearButtonClick={onClear}
        autoComplete="off"
      />
    </Wrapper>
  );
};
