import { Spinner } from '@shopify/polaris';
import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const PageLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out 2s forwards;
`;

export const PageLoader = () => (
  <PageLoaderContainer>
    <Spinner />
  </PageLoaderContainer>
);
