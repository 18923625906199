import styled, { css } from 'styled-components';
import { colorBlack } from '@shopify/polaris-tokens';

import { media } from '@styles/breakpoints';

export const LinkStyled = styled.a<{
  $active?: boolean;
  $color?: string | null;
}>`
  color: ${p => (p.$active ? p.$color : colorBlack)};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${p => (p.$color ? p.$color : colorBlack)};
    text-decoration: underline;
  }
`;

type BreakpointsGrid = {
  $xs?: number;
  $sm?: number;
  $md?: number;
  $lg?: number;
  $xl?: number;
  $xxl?: number;
  $wide?: number;
};

export const GridItem = styled.div<{ $fullSection?: boolean }>`
  flex: 1 1 100%;
  margin-top: 1.6rem;
  margin-left: 2rem;
  width: 100%;
  max-width: 100%;

  .Polaris-Card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ${p =>
    p.$fullSection &&
    css`
      .Polaris-Card__Section {
        height: 100%;
      }
    `}
`;

export const Grid = styled.div<BreakpointsGrid>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${GridItem} {
    ${p =>
      // eslint-disable-next-line
      p.$sm &&
      css`
        ${media.up('sm')} {
          flex: 1 1 calc(100% / ${p.$sm});
          max-width: calc(${`100% / ${p.$sm}`} - 2rem);
        }
      `}

    ${p =>
      // eslint-disable-next-line
      p.$md &&
      css`
        ${media.up('md')} {
          flex: 1 1 calc(100% / ${p.$md});
          max-width: calc(${`100% / ${p.$md}`} - 2rem);
        }
      `}

    ${p =>
      // eslint-disable-next-line
      p.$lg &&
      css`
        ${media.up('lg')} {
          flex: 1 1 calc(100% / ${p.$lg});
          max-width: calc(${`100% / ${p.$lg}`} - 2rem);
        }
      `}

    ${p =>
      // eslint-disable-next-line
      p.$xl &&
      css`
        ${media.up('xl')} {
          flex: 1 1 calc(100% / ${p.$xl});
          max-width: calc(${`100% / ${p.$xl}`} - 2rem);
        }
      `}

    ${p =>
      // eslint-disable-next-line
      p.$xxl &&
      css`
        ${media.up('xxl')} {
          flex: 1 1 calc(100% / ${p.$xxl});
          max-width: calc(${`100% / ${p.$xxl}`} - 2rem);
        }
      `}
  }
`;

export const LineClamp = styled.span<{ $line?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${p => (p.$line ? p.$line : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e1e3e5;
`;

export const PageContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 121.2rem;
`;

export const FirstLetterCapitalize = styled.span`
  display: inline-block;

  &:first-letter {
    text-transform: capitalize;
  }
`;
