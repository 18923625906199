import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { Redirect } from '@shopify/app-bridge/actions';
import type { ClientApplication } from '@shopify/app-bridge';

export function fetch(
  app: ClientApplication<any>,
): (uri: RequestInfo, options: RequestInit) => Promise<Response> {
  const fetchFunction = authenticatedFetch(app);

  return async (uri: RequestInfo, options: RequestInit): Promise<Response> => {
    const response = await fetchFunction(uri, options);

    if (
      response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1'
    ) {
      const authUrlHeader = response.headers.get(
        'X-Shopify-API-Request-Failure-Reauthorize-Url',
      );

      const redirect = Redirect.create(app);

      redirect.dispatch(Redirect.Action.APP, authUrlHeader || '/auth');
    }

    return response;
  };
}
