import { useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_COLLECTION_PRODUCTS_QUERY } from '../qraphql/queries';
import type { ProductsGraph } from '../qraphql/types';
import { productsGraphPaginatedAdapter } from '../adapters';

export function useCollectionProducts() {
  const [getCollectionProducts, { data, loading, error, fetchMore, refetch }] =
    useLazyQuery<{
      collection: ProductsGraph[];
    }>(GET_COLLECTION_PRODUCTS_QUERY, {
      variables: { first: 8 },
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    });

  const products: any = useMemo(() => {
    if (!data) return [];
    return productsGraphPaginatedAdapter(data.collection as any);
  }, [data]);

  return useMemo(
    () => ({
      error,
      collectionIsLoading: loading,
      getCollectionProducts,
      collectionProducts: products,
      collectionRefetch: refetch,
      fetchMoreCollectionProducts: fetchMore,
    }),
    [error, loading, getCollectionProducts, products, refetch, fetchMore],
  );
}
