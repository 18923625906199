import { useQuery } from 'react-query';

import { fetcher } from '@lib/fetcher';

import type { Plan } from '@pages/billing/types';

export interface Subscriptions {
  plans: Plan[] | [];
  specialOffer: Plan[] | [];
}

export async function getSubscriptions() {
  const response = await fetcher('/subscriptions');
  return response;
}

export function useSubscriptions() {
  return useQuery<Subscriptions>(['subscriptions'], getSubscriptions, {
    staleTime: Infinity,
  });
}
