import { ReactNode, useEffect, useMemo } from 'react';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useAppBridge } from '@shopify/app-bridge-react';

import { fetch } from '@lib/app-bridge';
import { AppProps } from 'next/app';

interface ConfiguredApolloProviderProps {
  pageProps: AppProps;
  children: ReactNode;
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

function useCreateApolloClient() {
  const app = useAppBridge();

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.app) window.app = app;
  }, [app]);

  return (
    apolloClient ??
    new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: new HttpLink({
        uri: `${process.env.HOST}/api/graphql`,
        fetch: fetch(app),
        credentials: 'include',
      }),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              products: relayStylePagination(),
              files: relayStylePagination(['type']),
              collections: relayStylePagination(),
            },
          },
          Product: {
            fields: {
              variants: relayStylePagination(),
            },
          },
          Collection: {
            fields: {
              products: relayStylePagination(),
              variants: relayStylePagination(),
            },
          },
        },
      }),
    })
  );
}

export function useInitializeApollo(initialState = null) {
  const _apolloClient = useCreateApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    // @ts-ignore
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((destination: unknown) =>
          sourceArray.every((source: unknown) => !isEqual(destination, source)),
        ),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(
  client: { cache: { extract: () => any } },
  pageProps: { props: { [x: string]: any } },
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: { [x: string]: any }) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const initializeApollo = useInitializeApollo(state);

  return useMemo(() => initializeApollo, [initializeApollo]);
}

export function ConfiguredApolloProvider({
  pageProps,
  children,
}: ConfiguredApolloProviderProps) {
  const client = useApollo(pageProps);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
