export function extractIdFromGid(gid: string) {
  const id = gid?.split('/')?.pop();
  if (id) return parseInt(id, 10);
  return gid;
}

const generateShopifyGid = (entityType: string, value: string | number) => {
  return `gid://shopify/${entityType}/${value}`;
};

export const generateShopifyProductGid = (value: string | number) => {
  return generateShopifyGid('Product', value);
};

export const generateShopifyCollectionGid = (value: string | number) => {
  return generateShopifyGid('Collection', value);
};
