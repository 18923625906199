import styled from 'styled-components';
import { colorSky } from '@shopify/polaris-tokens';

export const ModalInner = styled.div`
  position: relative;
  margin: 0 -2rem 2rem;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 1.6rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colorSky};
  }
`;

export const LoaderPosition = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

export const ProductLineGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ProductLineGridItem = styled.div`
  &:not(:first-child) {
    margin-left: 2rem;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CheckboxWrapper = styled.div<{ $hide: boolean }>`
  opacity: ${p => (p.$hide ? 0 : 1)};
  visibility: ${p => (p.$hide ? 'hidden' : 'visible')};
`;
