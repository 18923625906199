import { isEmpty } from 'ramda';
import { Banner, List } from '@shopify/polaris';
import { useFormContext } from 'react-hook-form';

function findAllByKey(obj: Record<string, any>, keyToFind: string): string[] {
  return Object.entries(obj || {}).reduce((acc, [key, value]) => {
    if (key === keyToFind) return [...acc, value];
    if (typeof value === 'object') {
      return [...acc, ...findAllByKey(value, keyToFind)];
    }
    return acc;
  }, [] as string[]);
}

export function parseErrors(errors: Record<string, any>) {
  return Object.keys(errors).reduce((acc, error) => {
    return [...acc, ...findAllByKey(errors[error], 'message')];
  }, [] as string[]);
}

export const ErrorsBanner = () => {
  const {
    formState: { errors },
  } = useFormContext();

  if (isEmpty(errors)) return null;

  const errorsMessages = parseErrors(errors);

  if (!errorsMessages.length) return null;

  return (
    <div style={{ marginBottom: '1.6rem' }}>
      <Banner
        status="critical"
        title={`There is ${errorsMessages.length} error('s) with this offer:`}
      >
        <List type="bullet">
          {errorsMessages.map((message, index) => (
            <List.Item key={index}>{message}</List.Item>
          ))}
        </List>
      </Banner>
    </div>
  );
};
