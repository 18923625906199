import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_PRODUCTS_QUERY } from '../qraphql/queries/getProducts';
import { productsGraphPaginatedAdapter } from '../adapters';
import type { ProductsGraph } from '../qraphql/types';

export const useProducts = () => {
  const { data, loading, error, fetchMore, refetch } = useQuery<ProductsGraph>(
    GET_PRODUCTS_QUERY,
    {
      variables: { first: 8 },
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const products = useMemo(() => {
    if (!data) return { nextPage: false, prevPage: false, products: [] };
    return productsGraphPaginatedAdapter(data);
  }, [data]);

  return useMemo(
    () => ({
      error,
      products,
      refetchProducts: refetch,
      productIsLoading: loading,
      fetchMoreProducts: fetchMore,
    }),
    [error, products, refetch, loading, fetchMore],
  );
};
