import styled from 'styled-components';
import { EmptySearchResult, Spinner } from '@shopify/polaris';
import { colorSky } from '@shopify/polaris-tokens';

import { isAutoRenewProduct } from '../helpers';
import type { Product } from '../types';
import { LoaderPosition } from './styles';
import { ProductItem } from './ProductItem';

const List = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li<{ $disable: boolean }>`
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid ${colorSky};
  opacity: ${p => (p.$disable ? 0.5 : 1)};
`;

type ProductListProps = {
  loading: boolean;
  products: Product[];
};

export const ProductList = ({ loading, products }: ProductListProps) => {
  if (!loading && !products?.length) {
    return (
      <div style={{ transform: 'translateY(15%)' }}>
        <EmptySearchResult
          title="No products found"
          description="Try changing the filters or search term"
          withIllustration
        />
      </div>
    );
  }

  return (
    <List>
      {products?.map((product: Product) => (
        <Item key={product.id} $disable={isAutoRenewProduct(product.title)}>
          <ProductItem product={product} />
        </Item>
      ))}
      {loading ? (
        <LoaderPosition>
          <Spinner />
        </LoaderPosition>
      ) : null}
    </List>
  );
};
