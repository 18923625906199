import { useMutation, useQueryClient } from 'react-query';
import { mergeDeepRight } from 'ramda';

import { fetcher } from '@lib/fetcher';
import { useToast } from '@hooks/useToast';
import { AppStore, Store } from '@pages/billing/types';

export async function updateAutoPurchase(value: boolean): Promise<Store> {
  const { store } = await fetcher('/stores/settings', {
    method: 'POST',
    data: { autoPurchase: value },
  });
  return store;
}

export function useUpdateAutoPurchase() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(updateAutoPurchase, {
    onSuccess: async data => {
      queryClient.setQueryData('stores', old => {
        return mergeDeepRight(old as unknown as AppStore, { store: data });
      });
      toast({ message: 'Auto-purchase updated' });
    },
    onError: () => {
      toast({
        isError: true,
        message: 'Error: Auto-purchase updated',
      });
    },
  });
}
