import { useQuery } from 'react-query';

import { fetcher } from '@lib/fetcher';

import type { AppStore } from '@pages/billing/types';

export async function getStores() {
  const stores = await fetcher('/stores');
  return stores;
}

export function useStores() {
  return useQuery<AppStore>(['stores'], getStores, {
    staleTime: Infinity,
  });
}
