import { useEffect, useMemo, useRef, useState } from 'react';
import { AppProvider as PolarisAppProvider } from '@shopify/polaris';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import type { AppContext, AppProps } from 'next/app';

import { RoutePropagator, Link } from '@components/index';
import { ConfiguredApolloProvider } from '@lib/apollo-client';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import translations from '@shopify/polaris/locales/en.json';

import '@shopify/polaris/build/esm/styles.css';
import '@styles/polaris.css';
import '@styles/styles.css';

import { ContextualSaveBarProvider } from '@components/ContextualSaveBar';
import { ConfirmModal } from '@components/ContextualSaveBar/ConfirmModal';
import { AppProvider } from '@contexts/app-context';
import { Beacon } from '@components/Beacon/Beacon';

interface MyAppProps extends AppProps {
  host: string;
  error:
    | (Error & {
        statusCode?: number;
      })
    | null;
}

const API_KEY = process.env.SHOPIFY_API_KEY;

function MyApp({ Component, pageProps, host }: MyAppProps) {
  const appHost = useRef(host);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  if ((!host || !appHost.current) && typeof window !== 'undefined') {
    appHost.current = window.appHost;
  }

  const config = useMemo(
    () => ({
      host: appHost.current,
      apiKey: API_KEY || '',
      forceRedirect: true,
    }),
    [],
  );

  useEffect(() => {
    if (host || appHost.current) {
      window.appHost = host || appHost.current;
    }
  }, []);

  return (
    <AppProvider>
      <ContextualSaveBarProvider>
        <PolarisAppProvider i18n={translations} linkComponent={Link}>
          <AppBridgeProvider config={config}>
            <RoutePropagator />
            <ConfiguredApolloProvider pageProps={pageProps}>
              <QueryClientProvider client={queryClient}>
                <Component {...pageProps} />
                <ReactQueryDevtools />
                <ConfirmModal />
                <Beacon />
              </QueryClientProvider>
            </ConfiguredApolloProvider>
          </AppBridgeProvider>
        </PolarisAppProvider>
      </ContextualSaveBarProvider>
    </AppProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }: AppContext) => ({
  host: ctx.query.host,
  error: ctx.err,
});

export default MyApp;
