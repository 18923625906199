import { MediaCardImage } from '../styles';

type ImageProps = {
  image: string | null | undefined;
};

export const Image = ({ image }: ImageProps) => {
  return (
    <MediaCardImage $image={image}>
      {image && <img src={image} alt="" />}
    </MediaCardImage>
  );
};
