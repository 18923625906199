import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_ID_QUERY = gql`
  query GetProductById($productId: ID!) {
    product(id: $productId) {
      id
      title
    }
  }
`;
