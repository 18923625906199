import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const SidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
`;

export const SidebarHeader = styled.div`
  border-bottom: 0.1rem solid var(--p-divider);
`;

export const SidebarBody = styled.div.attrs({
  'data-polaris-scrollable': true,
})`
  height: 100%;
  overflow-y: auto;

  .Polaris-Labelled__HelpText {
    font-size: 1.2rem;
  }
`;

export const SidebarFooter = styled.div`
  border-top: 0.1rem solid var(--p-divider);
`;

export const SidebarButton = styled.button.attrs(() => ({ type: 'button' }))`
  position: relative;
  display: flex;
  border: 0;
  padding: 20px;
  width: 100%;
  color: currentColor;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  background-color: transparent;

  &:hover {
    background-color: rgb(249, 250, 251);
  }
`;
