import styled from 'styled-components';

const colors = {
  green: '#b0c7c4',
  red: '#ebd7d0',
};

export const HeroWrapper = styled.div<{ color: 'green' | 'red' | string }>`
  border-radius: 0.8rem;
  padding: 4.8rem;
  min-height: 36rem;
  background-color: ${p =>
    p.color === 'green' || p.color === 'red' ? colors[p.color] : p.color};
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
`;

export const HeroBody = styled.div`
  max-width: 52rem;
`;
