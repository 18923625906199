import { useEffect } from 'react';
import { useStores } from '@hooks/useStores';

export function useBeacon() {
  const { data: stores } = useStores();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window?.Beacon !== 'undefined'
    ) {
      if (stores) {
        const { shop } = stores;
        const { plan } = stores?.subscription || {};

        window.Beacon('on', 'open', () => {
          window.Beacon('identify', {
            url: shop.domain,
            'store-url': shop.domain,
            subscription: plan?.name || 'Unknown',
            'shopify-plan': shop.plan_name,
          });
        });
      }
    }
  }, [stores]);
}
