import { mergeDeepRight } from 'ramda';

import {
  extractIdFromGid,
  getNodesFromConnections,
  createPaginationCursorBased,
} from '@graphql/utilities';
import type {
  ImageGraph,
  VariantGraph,
  ProductsGraph,
  VariantGraphResponse,
} from '@components/ProductsPicker/qraphql/types';

function imagesAdapter(images: ImageGraph[]) {
  return images.map(({ id, transformedSrc }) => ({ id, src: transformedSrc }));
}

function variantsAdapter(variants: VariantGraph[]) {
  return variants.map(variant => ({
    ...variant,
    id: extractIdFromGid(variant.id),
    title: variant.title,
  }));
}

export const productsGraphPaginatedAdapter = (data: ProductsGraph) => {
  const { nextPage, previousPage } = createPaginationCursorBased(data.products);

  const products = getNodesFromConnections(data.products).map(product => {
    const variants = variantsAdapter(getNodesFromConnections(product.variants));
    const images = imagesAdapter(getNodesFromConnections(product.images));

    return mergeDeepRight(product, {
      images,
      variants,
      body_html: product.descriptionHtml,
      id: extractIdFromGid(product.id),
    });
  });

  return { nextPage, previousPage, products };
};

export const variantsGraphPaginatedAdapter = (data: VariantGraphResponse) => {
  const variants = variantsAdapter(
    getNodesFromConnections(data.product.variants),
  );
  const { nextPage, previousPage } = createPaginationCursorBased(
    data.product.variants,
  );

  return { nextPage, previousPage, variants };
};
