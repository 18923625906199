import React, { createContext, useMemo, useReducer } from 'react';

import { Action, ActionType, Dispatch, State } from './types';
import {
  addProduct,
  addVariant,
  updateProduct,
  removeProduct,
  removeProductVariant,
} from './utilities';

type ProductsContext = {
  state: State;
  dispatch: Dispatch;
};

type ProductsProviderProps = {
  initialValues: Partial<State>;
  children: React.ReactNode;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD_PRODUCT:
      return addProduct(state, action);
    case ActionType.UPDATE_PRODUCT:
      return updateProduct(state, action);
    case ActionType.REMOVE_PRODUCT:
      return removeProduct(state, action);
    case ActionType.ADD_VARIANT:
      return addVariant(state, action);
    case ActionType.REMOVE_VARIANT:
      return removeProductVariant(state, action);
    case ActionType.CLEAR:
      return state;
    case ActionType.SCROLLED_BOTTOM:
      return { ...state, scrolledBottom: action.payload };
    default:
      return state;
  }
};

const ProductsContext = createContext<ProductsContext | undefined>(undefined);

const initialState: State = {
  selected: [],
  productsSelected: 0,
  scrolledBottom: false,
  selectMultiple: true,
  selectDisabled: false,
  maxSelectedProducts: 5,
};

export const ProductsProvider = (props: ProductsProviderProps) => {
  const { initialValues, children } = props;
  let isDisabled = Boolean(
    initialValues?.selected?.length && !initialValues.selectMultiple,
  );

  if (initialValues?.selected?.length && initialValues?.maxSelectedProducts) {
    isDisabled =
      initialValues?.selected?.length >= initialValues?.maxSelectedProducts;
  }

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
    selectDisabled: isDisabled,
  });
  const values = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ProductsContext.Provider value={values}>
      {children}
    </ProductsContext.Provider>
  );
};

export function useProductsContext() {
  const context = React.useContext(ProductsContext);
  if (context === undefined) {
    throw new Error(
      'useProductsContext must be used within a ProductsProvider',
    );
  }
  return context;
}
