import { useEffect } from 'react';
import { Options } from '@shopify/app-bridge/actions/ContextualSaveBar';

import { useContextualSaveBar } from './useContextualSaveBar';
import {
  ActionType,
  useContextualSaveBarContext,
} from './contextual-save-bar-context';

type ContextualSaveBarProps = {
  isShow: boolean;
  options?: Partial<Options>;
  onSaveAction(): void;
  onDiscardAction(): void;
};

export const ContextualSaveBar = (props: ContextualSaveBarProps) => {
  const { isShow, options, onSaveAction, onDiscardAction } = props;
  const { dispatch } = useContextualSaveBarContext();
  const { showSaveBar, hideShowBar } = useContextualSaveBar({
    options,
    onSave: onSaveAction,
    onDiscard: onDiscardAction,
  });

  useEffect(() => {
    if (isShow) showSaveBar();
    if (!isShow) hideShowBar();

    dispatch({ type: ActionType.SET_UNSAVED_CHANGES, payload: isShow });
  }, [dispatch, hideShowBar, isShow, showSaveBar]);

  return null;
};
