import { gql } from '@apollo/client';

import {
  PAGE_INFO,
  PRODUCT_IMAGES,
  PRODUCT_OPTIONS,
  PRODUCT_VARIANTS,
} from './fragments';

export const GET_PRODUCTS_QUERY = gql`
  ${PAGE_INFO}
  ${PRODUCT_IMAGES}
  ${PRODUCT_VARIANTS}
  ${PRODUCT_OPTIONS}
  query GetProducts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
  ) {
    products(
      first: $first
      last: $last
      after: $after
      before: $before
      query: $query
      sortKey: TITLE
    ) {
      ...PageInfo
      edges {
        cursor
        node {
          id
          title
          handle
          totalVariants
          descriptionHtml
          tags
          ...Variants
          ...Options
          ...Images
        }
      }
    }
  }
`;
