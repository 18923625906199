import { gql } from '@apollo/client';

export const PRODUCT_VARIANTS = gql`
  fragment Variants on Product {
    variants(first: 100) {
      edges {
        node {
          id
          title
          price
          displayName
        }
      }
    }
  }
`;

export const PRODUCT_IMAGES = gql`
  fragment Images on Product {
    images(first: 5) {
      edges {
        node {
          id
          originalSrc
          transformedSrc
        }
      }
    }
  }
`;

export const PAGE_INFO = gql`
  fragment PageInfo on ProductConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
`;

export const PRODUCT_OPTIONS = gql`
  fragment Options on Product {
    options {
      id
      name
      values
      position
    }
  }
`;
