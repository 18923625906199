import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { ContextualSaveBar } from '@shopify/app-bridge/actions';
import { Options } from '@shopify/app-bridge/actions/ContextualSaveBar';
import { mergeDeepRight } from 'ramda';

type ContextualSaveBarOptions = {
  options?: Partial<Options>;
  onSave(): void;
  onDiscard(): void;
};

export function useContextualSaveBar({
  options,
  onSave,
  onDiscard,
}: ContextualSaveBarOptions) {
  const app = useAppBridge();
  const isMounted = useRef(false);

  const contextualSaveBar = useMemo(() => ContextualSaveBar.create(app), []);

  const show = useCallback(() => {
    contextualSaveBar.set(
      mergeDeepRight({ saveAction: { loading: false } }, options || {}),
    );
    contextualSaveBar.dispatch(ContextualSaveBar.Action.SHOW);
  }, [contextualSaveBar, options]);

  const hide = useCallback(() => {
    contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
  }, [contextualSaveBar]);

  useEffect(() => {
    if (!isMounted.current) {
      contextualSaveBar.subscribe(ContextualSaveBar.Action.DISCARD, () => {
        onDiscard();
        contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
      });

      contextualSaveBar.subscribe(ContextualSaveBar.Action.SAVE, () => {
        onSave();
        contextualSaveBar.set({ saveAction: { loading: true } });
      });
    }
    isMounted.current = true;
  }, [contextualSaveBar, onDiscard, onSave]);

  useEffect(() => {
    return () => {
      contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
      contextualSaveBar.unsubscribe();
    };
  }, [contextualSaveBar]);

  return {
    showSaveBar: show,
    hideShowBar: hide,
  };
}
