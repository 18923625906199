import { useMemo } from 'react';

import type { Product } from '@components/ProductsPicker/types';
import { ProductsProvider } from './context/products-context';
import { ProductContainer } from './components/ProductsContainer';
import { inputProductsAdapter } from './helpers';

type ProductsPickerProps = {
  open: boolean;
  onCancel(): void;
  selectMultiple?: boolean;
  initialSelection: Product[];
  maxSelectedProducts?: number;
  onSelection(selectionPayload: Product[]): void;
};

export const ProductsPicker = (props: ProductsPickerProps) => {
  const {
    open,
    onCancel,
    onSelection,
    initialSelection,
    selectMultiple = true,
    maxSelectedProducts = 5,
  } = props;

  const initialValues = useMemo(
    () => ({
      selectMultiple,
      maxSelectedProducts,
      selected: inputProductsAdapter(initialSelection),
    }),
    [initialSelection, maxSelectedProducts, selectMultiple],
  );

  if (!open) return null;

  return (
    <ProductsProvider initialValues={initialValues}>
      <ProductContainer
        open={open}
        onSelection={onSelection}
        onCancel={onCancel}
      />
    </ProductsProvider>
  );
};
