import styled, { css } from 'styled-components';

export const TabsNavigation = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Tab = styled.button<{ $active: boolean }>`
  flex: 1 1 100%;
  border: 0;
  border-bottom: 3px solid transparent;
  padding: 2rem;
  text-align: center;
  font-size: 14px;
  color: #6d7175;
  background-color: transparent;
  cursor: pointer;

  ${p =>
    p.$active &&
    css`
      border-bottom-color: #008060;
      color: #000000;
    `}
`;
