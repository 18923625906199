import React, { useMemo, useReducer } from 'react';

export enum ActionType {
  HIDE_POST_PURCHASE_BANNER = 'HIDE_POST_PURCHASE_BANNER',
  POST_PURCHASE_APP_ENABLED = 'POST_PURCHASE_APP_ENABLED',
  SHOW_AFFILIATE_BANNER = 'SHOW_AFFILIATE_BANNER',
  SHOW_PENDING_BANNER = 'SHOW_PENDING_BANNER',
}

interface State {
  postPurchaseEnabled: boolean;
  hidePostPurchaseBanner: boolean;
  showAffiliateBanner: boolean;
  showPendingBanner: boolean;
}

type Action = { type: ActionType; payload: any };
type Dispatch = (action: Action) => void;

interface AppContextState {
  state: State;
  dispatch: Dispatch;
}

type AppProviderProps = {
  children: React.ReactNode;
};

const AppContext = React.createContext<AppContextState | undefined>(undefined);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.HIDE_POST_PURCHASE_BANNER:
      return { ...state, hidePostPurchaseBanner: action.payload };
    case ActionType.POST_PURCHASE_APP_ENABLED:
      return { ...state, postPurchaseEnabled: action.payload };
    case ActionType.SHOW_AFFILIATE_BANNER:
      return { ...state, showAffiliateBanner: action.payload };
    case ActionType.SHOW_PENDING_BANNER:
      return { ...state, showPendingBanner: action.payload };
    default:
      return state;
  }
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {
    postPurchaseEnabled: false,
    hidePostPurchaseBanner: true,
    showAffiliateBanner: true,
    showPendingBanner: true,
  });
  const values = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
}
