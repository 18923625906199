import Link from 'next/link';
import { Stack, TextStyle } from '@shopify/polaris';

import { LinkStyled } from '@styles/Global';
import { useRouter } from 'next/router';
import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';

export const navigationItems = [
  {
    url: '/',
    label: 'Offers',
  },
  {
    url: '/billing',
    label: 'Billing',
  },
  {
    url: '/analytics',
    label: 'Analytics',
  },
  {
    url: '/translations',
    label: 'Translations',
  },
  {
    url: 'https://presidio-creative.gitbook.io/presidio-up/',
    label: 'FAQs',
  },
];

type NavigationItemProps = {
  url: string;
  label: string;
  isActive: boolean;
};

export const NavigationItem = (props: NavigationItemProps) => {
  const { label, url, isActive } = props;
  const redirect = useAppBridgeRedirect();

  const handleLink = (e: any) => {
    e.preventDefault();
    redirect.remote({ url, newContext: true });
  };

  if (/^https:\/\//.test(url)) {
    return (
      <LinkStyled
        $active={isActive}
        $color="#008060"
        // @ts-ignore
        onClick={handleLink}
      >
        <TextStyle variation="strong">{label}</TextStyle>
      </LinkStyled>
    );
  }

  return (
    <Link href={url} passHref>
      <LinkStyled $active={isActive} $color="#008060">
        <TextStyle variation="strong">{label}</TextStyle>
      </LinkStyled>
    </Link>
  );
};

export const Navigation = () => {
  const { pathname } = useRouter();

  return (
    <Stack spacing="extraLoose">
      {navigationItems.map(({ label, url }) => (
        <NavigationItem
          key={url}
          label={label}
          url={url}
          isActive={pathname === url}
        />
      ))}
    </Stack>
  );
};
