import { useMemo } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { useStores } from '@hooks/useStores/useStores';

export function useStoreCurrency() {
  const { data: stores } = useStores();
  const currencySymbol = getSymbolFromCurrency(stores?.shop?.currency || 'USD');

  return useMemo(
    () => ({
      currency: stores?.shop?.currency,
      symbol: currencySymbol,
    }),
    [currencySymbol, stores?.shop?.currency],
  );
}
