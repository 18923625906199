import React from 'react';
import { useLink } from '@hooks/useLink';
import { LinkStyled } from '@styles/Global';

type LinkProps = {
  url: string;
  children?: React.ReactNode;
  external?: boolean;
  download?: string | boolean;
  [key: string]: unknown;
};

export const Link = ({ children, external, url, ...rest }: LinkProps) => {
  const mailto = url?.startsWith('mailto:');
  const handleClick = useLink();

  if (external || mailto) {
    const target = external ? '_blank' : '_top';
    const rel = external ? 'noopener noreferrer' : undefined;

    return (
      <a target={target} href={url} rel={rel} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <LinkStyled {...rest} href={url} onClick={handleClick(url)}>
      {children}
    </LinkStyled>
  );
};
