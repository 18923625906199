import React from 'react';
import Link from 'next/link';
import { Card, TextStyle } from '@shopify/polaris';
import type { ActionListItemDescriptor } from '@shopify/polaris';

import { Dropdown } from '@components/Dropdown';
import { transformImageUrl } from '@helpers/transformImageUrl';
import { LinkStyled } from '@styles/Global';
import { Image, Title } from './components';
import {
  MediaCardInner,
  MediaCardContent,
  MediaCardHeader,
  MediaCardDropdown,
} from './styles';

type MediaCardProps = {
  id: number;
  name: string;
  description: React.ReactNode;
  previewImage: string | null | undefined;
  content?: React.ReactNode;
  popoverActions?: ActionListItemDescriptor[];
};

export const MediaCard = (props: MediaCardProps) => {
  const {
    id,
    name,
    previewImage,
    content = null,
    description,
    popoverActions,
  } = props;

  return (
    <Card>
      <MediaCardInner>
        <Image image={transformImageUrl(previewImage ?? '', 288, 288)} />
        <MediaCardContent>
          <MediaCardHeader>
            <div>
              <Link href={`/offers/${id}`} passHref>
                <LinkStyled>
                  <Title>{name}</Title>
                </LinkStyled>
              </Link>
              {description && (
                <TextStyle variation="subdued">{description}</TextStyle>
              )}
            </div>
            {popoverActions && (
              <MediaCardDropdown>
                <Dropdown
                  popoverActions={popoverActions}
                  preferredAlignment="center"
                />
              </MediaCardDropdown>
            )}
          </MediaCardHeader>
          {content}
        </MediaCardContent>
      </MediaCardInner>
    </Card>
  );
};
