import { ConnectionGraph } from '@graphql/types';
import { getCursors } from '@graphql/utilities';

export function createPaginationCursorBased<T>(connection: ConnectionGraph<T>) {
  const { hasNextPage, hasPreviousPage } = connection.pageInfo;
  const { endCursor, startCursor } = getCursors(connection.edges);

  return {
    nextPage: hasNextPage ? endCursor : null,
    previousPage: hasPreviousPage ? startCursor : null,
  };
}
