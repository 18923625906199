import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppBridge, useRoutePropagation } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useRouteChangeLoader } from '@hooks/useRouteChangeLoader';

export const RoutePropagator = () => {
  const router = useRouter();
  const appBridge = useAppBridge();

  useRoutePropagation(router.asPath);

  useRouteChangeLoader();

  useEffect(() => {
    const unsubscribe = appBridge.subscribe(Redirect.Action.APP, ({ path }) => {
      if (router.asPath !== path) {
        router.push(path);
      }
    });

    return unsubscribe;
  }, [appBridge, router]);

  return null;
};
