import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { getNodesFromConnections } from '@graphql/utilities';
import { GET_COLLECTIONS_QUERY } from '../qraphql/queries/getCollections';
import type { Collection } from '../types';

export const useCollections = () => {
  const { data, loading, error } = useQuery(GET_COLLECTIONS_QUERY, {
    variables: { first: 100 },
    notifyOnNetworkStatusChange: true,
  });

  const collections: Collection[] = useMemo(() => {
    if (!data) return [];
    return getNodesFromConnections(data.collections);
  }, [data]);

  return useMemo(
    () => ({
      error,
      data: collections,
      isLoading: loading,
    }),
    [collections, loading, error],
  );
};
