import { gql } from '@apollo/client';

export const GET_PRODUCT_VARIANTS_QUERY = gql`
  query GetProductVariants($id: ID!, $after: String) {
    product(id: $id) {
      id
      variants(first: 100, after: $after) {
        edges {
          cursor
          node {
            id
            title
            price
            displayName
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`;
