import React from 'react';
import { useRouter } from 'next/router';

import {
  ActionType,
  useContextualSaveBarContext,
} from '@components/ContextualSaveBar';

export function useLink() {
  const router = useRouter();
  const { state, dispatch } = useContextualSaveBarContext();

  return (url: string) => async (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    if (state.isUnsavedChanges) {
      dispatch({ type: ActionType.OPEN_CONFIRM_MODAL, payload: url });
      return;
    }

    await router.push(url);
  };
}
