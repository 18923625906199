import { useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { useDebounce } from '../hooks/useDebounce';
import { useProducts } from '../hooks/useProducts';
import { useCollectionProducts } from '../hooks/useCollectionProducts';

import { ActionType, useProductsContext } from '../context';
import { ProductList, ProductsFilters, SelectedProductList } from './index';
import { ModalInner } from './styles';
import { useBuildSearchQuery } from '../hooks/useBuildSearchQuery';

export const Products = () => {
  const isMounted = useRef(false);
  const client = useApolloClient();
  const { products, fetchMoreProducts, refetchProducts, productIsLoading } =
    useProducts();
  const {
    collectionProducts,
    collectionIsLoading,
    getCollectionProducts,
    fetchMoreCollectionProducts,
  } = useCollectionProducts();
  const { state, dispatch } = useProductsContext();
  const [tag, setTag] = useState('');
  const [query, setQuery] = useState('');
  const [collectionId, setCollectionId] = useState<string | null>(null);

  const debouncedTag = useDebounce(tag, 1000);
  const debouncedQuery = useDebounce(query, 1000);
  const searchQuery = useBuildSearchQuery(debouncedQuery, debouncedTag);

  const handleTagChange = useCallback(value => setTag(value), []);
  const handleQueryChange = useCallback(value => setQuery(value), []);
  const handleCollectionChange = useCallback(
    value => setCollectionId(value),
    [],
  );

  const isLoading = productIsLoading || collectionIsLoading;
  const productList = collectionId
    ? collectionProducts.products
    : products?.products;

  const getMoreProducts = useCallback(() => {
    fetchMoreProducts({ variables: { after: products?.nextPage } })?.finally(
      () => {
        dispatch({ type: ActionType.SCROLLED_BOTTOM, payload: false });
      },
    );
  }, [dispatch, fetchMoreProducts, products?.nextPage]);

  const getMoreCollectionProducts = useCallback(() => {
    if (fetchMoreCollectionProducts) {
      fetchMoreCollectionProducts({
        variables: { after: collectionProducts.nextPage },
      })?.finally(() => {
        dispatch({ type: ActionType.SCROLLED_BOTTOM, payload: false });
      });
    }
  }, [collectionProducts.nextPage, dispatch, fetchMoreCollectionProducts]);

  useEffect(() => {
    if (
      state.scrolledBottom &&
      (!products?.nextPage || !collectionProducts.nextPage)
    ) {
      dispatch({ type: ActionType.SCROLLED_BOTTOM, payload: false });
    }

    if (state.scrolledBottom) {
      if (!collectionId && products?.nextPage) {
        getMoreProducts();
      }

      if (collectionId && collectionProducts.nextPage) {
        getMoreCollectionProducts();
      }
    }
  }, [
    collectionId,
    collectionProducts.nextPage,
    dispatch,
    getMoreCollectionProducts,
    getMoreProducts,
    products?.nextPage,
    state.scrolledBottom,
  ]);

  useEffect(() => {
    if (isMounted.current) {
      refetchProducts({ query: searchQuery })?.finally();
    }
  }, [refetchProducts, searchQuery]);

  useEffect(() => {
    if (collectionId) {
      getCollectionProducts({ variables: { id: collectionId } });
    }
  }, [collectionId, getCollectionProducts]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      client.clearStore().then();
    };
  }, [client]);

  return (
    <>
      <div style={{ paddingBottom: '3px' }}>
        <ProductsFilters
          onTagChange={handleTagChange}
          onQueryChange={handleQueryChange}
          onCollectionChange={handleCollectionChange}
        />
      </div>
      <SelectedProductList />
      <ModalInner>
        <ProductList loading={isLoading} products={productList} />
      </ModalInner>
    </>
  );
};
