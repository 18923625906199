import React from 'react';
import { DisplayText } from '@shopify/polaris';

type HeadingProps = {
  children: React.ReactNode;
};

export const Heading = ({ children }: HeadingProps) => {
  return (
    <DisplayText element="h2" size="extraLarge">
      {children}
    </DisplayText>
  );
};
