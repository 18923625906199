import React from 'react';

import { ErrorsBanner } from '@components/Banner/ErrorsBanner';
import { Button } from './components';
import {
  SidebarBody,
  SidebarInner,
  SidebarHeader,
  SidebarWrapper,
  SidebarFooter,
} from './styles';

type SidebarProps = {
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
};

export const Sidebar = ({ header, body, footer }: SidebarProps) => {
  return (
    <SidebarWrapper>
      <ErrorsBanner />
      <SidebarInner className="Polaris-Card">
        <SidebarHeader>{header}</SidebarHeader>
        <SidebarBody>{body}</SidebarBody>
        <SidebarFooter>{footer}</SidebarFooter>
      </SidebarInner>
    </SidebarWrapper>
  );
};

Sidebar.Button = Button;
