import { VariantItem } from './VariantItem';
import { List } from './styles';
import type { ExtendVariant } from '../types';

type ProductVariantListProps = {
  disabled: boolean;
  selected: number[];
  variants: ExtendVariant[];
};

export const ProductVariants = (props: ProductVariantListProps) => {
  const { selected, variants, disabled } = props;

  return (
    <List style={{ position: 'relative', paddingLeft: '4rem' }}>
      <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
        {variants.map((variant: ExtendVariant) => (
          <VariantItem
            disabled={disabled}
            key={variant.id}
            variant={variant}
            checked={selected.length ? selected.includes(variant.id) : false}
          />
        ))}
      </div>
    </List>
  );
};
