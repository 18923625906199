import { Product } from '@components/ProductsPicker';

export enum ActionType {
  CLEAR = 'CLEAR',
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_VARIANT = 'ADD_VARIANT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  REMOVE_VARIANT = 'REMOVE_VARIANT',
  SCROLLED_BOTTOM = 'SCROLLED_BOTTOM',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
}

export type Action = { type: ActionType; payload: any };
export type Dispatch = (action: Action) => void;

interface ExtendProduct extends Product {
  variantIds: number[];
}

export type State = {
  selected: ExtendProduct[];
  scrolledBottom: boolean;
  selectMultiple: boolean;
  productsSelected: number;
  selectDisabled: boolean;
  maxSelectedProducts: number;
};
