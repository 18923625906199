export const breakpoints = {
  /**
   * The number of columns to span on extra small devices (<576px)
   */
  xs: 576,

  /**
   * The number of columns to span on small devices (≥576px)
   */
  sm: 576,

  /**
   * The number of columns to span on medium devices (≥768px)
   */
  md: 768,

  /**
   * The number of columns to span on large devices (≥992px)
   */
  lg: 992,

  /**
   * The number of columns to span on extra large devices (≥1200px)
   */
  xl: 1200,

  /**
   * The number of columns to span on extra extra large devices (≥1400px)
   */
  xxl: 1400,

  /**
   * The number of columns to span on extra extra large devices (≥1600px)
   */
  wide: 1600,
};

type Size = keyof typeof breakpoints;

export const media = {
  up(size: Size | number) {
    const point = typeof size === 'number' ? size : breakpoints[size];
    return `@media (min-width: ${point}px)`;
  },
  down(size: Size | number) {
    const point = typeof size === 'number' ? size : breakpoints[size];
    return `@media (max-width: ${point}px)`;
  },
  between(minSize: Size | number, maxSize: Size | number) {
    const min = typeof minSize === 'number' ? minSize : breakpoints[minSize];
    const max = typeof maxSize === 'number' ? maxSize : breakpoints[maxSize];
    return `@media (min-width: ${min}px) and (max-width: ${max}px)`;
  },
};
