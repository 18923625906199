import React, { useCallback } from 'react';
import { Button, Icon, IconSource, TextStyle } from '@shopify/polaris';
import { HintMajor, ExternalSmallMinor } from '@shopify/polaris-icons';

import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';
import { Content, Wrapper, IconHolder, Header } from './styles';

type TipsBannerProps = {
  title: string;
  iconSource?: IconSource;
  iconColor?: string;
  buttonText?: string;
  url?: string;
  children: React.ReactNode;
};

export const TipsBanner = ({
  url,
  title,
  children,
  iconColor,
  iconSource,
  buttonText,
}: TipsBannerProps) => {
  const redirect = useAppBridgeRedirect();

  const handleClick = useCallback(() => {
    if (url) redirect.remote({ url, newContext: true });
  }, [redirect, url]);

  return (
    <Wrapper>
      <IconHolder $color={iconColor}>
        <Icon source={iconSource ?? HintMajor} />
      </IconHolder>
      <div style={{ marginLeft: 5 }}>
        <Header>
          <h2>{title}</h2>
          {url ? <Icon source={ExternalSmallMinor} color="subdued" /> : null}
        </Header>
        <TextStyle variation="subdued">
          <Content>{children}</Content>
        </TextStyle>
        {url ? (
          <div>
            <Button plain onClick={handleClick}>
              {buttonText || 'Open'}
            </Button>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
};
