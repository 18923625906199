import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

interface App {
  title: string;
  isPostPurchaseAppInUse: boolean;
}

const APP_POST_PURCHASE_QUERY = gql`
  query {
    app {
      title
      isPostPurchaseAppInUse
    }
  }
`;

export const useIsPostPurchase = () => {
  const { data, loading, error, refetch } = useQuery(APP_POST_PURCHASE_QUERY);

  return useMemo(
    () => ({
      error,
      refetch,
      data: data?.app as App,
      isLoading: loading,
    }),
    [error, refetch, data, loading],
  );
};
