import { useStores } from '@hooks/useStores';
import { Usage, useUsage } from '@pages/billing/hooks';
import { AppStore } from '@pages/billing/types';

export function getSubscriptionStatus(
  stores: AppStore | undefined,
  usage: Usage | undefined,
) {
  const statuses: string[] = [];

  if (usage?.limit === 'unlimited') {
    statuses.push('affiliate');
  }

  if (stores && stores.pendingSubscription !== null) {
    statuses.push('pending');
  }

  if (stores && stores.subscription !== null) {
    statuses.push('subscription');
  }

  return statuses;
}

export function useSubscriptionStatus() {
  const { data: stores } = useStores();
  const { data: usage } = useUsage();
  const status: string[] = getSubscriptionStatus(stores, usage);
  const { plan, confirmationUrl } = stores?.pendingSubscription ?? {};

  return { status, plan, confirmationUrl };
}
