import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { fetcher } from '@lib/fetcher';
import { useStores } from '@hooks/useStores/useStores';
import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';

interface Params {
  id: number;
  returnUrl: string;
}

export async function createSubscription({ id, returnUrl }: Params) {
  const response = await fetcher('/subscriptions', {
    method: 'POST',
    data: {
      planId: id,
      returnUrl,
    },
  });
  return response;
}

export function useSubscriptionCreate() {
  const queryClient = useQueryClient();
  const { data: stores } = useStores();
  const redirect = useAppBridgeRedirect();

  const returnUrl = useMemo(() => {
    return `https://${stores?.shop?.domain}/admin/apps/${process.env.SHOPIFY_API_KEY}/`;
  }, [stores]);

  return useMutation((id: number) => createSubscription({ id, returnUrl }), {
    onSuccess: async data => {
      if (data.subscription.confirmationUrl) {
        redirect.remote(data.subscription.confirmationUrl);
      } else {
        await queryClient.invalidateQueries('stores');
        await queryClient.invalidateQueries('usage');
        redirect.app('/');
      }
    },
  });
}
