import { omit, map, prop } from 'ramda';

import type { Product } from '@components/ProductsPicker/types';

export function isAutoRenewProduct(title: string) {
  return /(auto\srenew)$/gim.test(title);
}

export function inputProductsAdapter(products: Product[]) {
  return (products ?? [])?.map(product => ({
    ...product,
    variantIds: map(prop('id'), product?.variants ?? []),
  }));
}

export function outputProductsAdapter(products: Product[]) {
  return map(omit(['variantIds']), products ?? []);
}
