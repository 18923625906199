import { useQuery, UseQueryOptions } from 'react-query';

import { fetcher } from '@lib/fetcher';

export interface Usage {
  currentBillingPeriod?: { starts: string; ends: string };
  used: number;
  limit: number | 'unlimited';
  spend?: number;
}

export async function getUsage() {
  const response = await fetcher('/stores/usage');
  return response;
}

export function useUsage(options?: UseQueryOptions<Usage>) {
  return useQuery<Usage>(['usage'], getUsage, {
    staleTime: Infinity,
    ...options,
  });
}
