import { useCallback } from 'react';
import { Checkbox } from '@shopify/polaris';

import { ActionType, useProductsContext } from '../context';
import type { ExtendVariant } from '../types';

import { ListItem, ProductLineGrid, ProductLineGridItem } from './styles';

type VariantItemProps = {
  variant: ExtendVariant;
  checked: boolean;
  disabled: boolean;
};

export const VariantItem = (props: VariantItemProps) => {
  const { checked, disabled, variant } = props;
  const { dispatch } = useProductsContext();

  const handleChange = useCallback(
    (value: boolean) => {
      const action = value ? 'ADD_VARIANT' : 'REMOVE_VARIANT';
      dispatch({ type: ActionType[action], payload: variant });
    },
    [dispatch, variant],
  );

  return (
    <ProductLineGrid>
      <ProductLineGridItem>
        <Checkbox
          labelHidden
          label="Select variant"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        />
      </ProductLineGridItem>
      <ProductLineGridItem>
        <ListItem
          style={{
            borderBottom: 0,
            padding: '1rem 0',
          }}
        >
          {variant.title}
        </ListItem>
      </ProductLineGridItem>
    </ProductLineGrid>
  );
};
