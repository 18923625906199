import { gql } from '@apollo/client';

export const GET_COLLECTIONS_QUERY = gql`
  query GetCollections($first: Int) {
    collections(first: $first) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          handle
          title
        }
      }
    }
  }
`;
