import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export interface ShopTimezone {
  timezoneOffsetMinutes: number;
  timezoneOffset: string;
  ianaTimezone: string;
  timezoneAbbreviation: string;
}

const GET_SHOP_TIMEZONE_QUERY = gql`
  query getShopTimeZone {
    shop {
      id
      ianaTimezone
      timezoneOffset
      timezoneAbbreviation
      timezoneOffsetMinutes
    }
  }
`;

export const useShopTimezone = () => {
  const { data, loading, error } = useQuery<{ shop: ShopTimezone }>(
    GET_SHOP_TIMEZONE_QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  );

  return useMemo(
    () => ({ error, data: data?.shop, isLoading: loading }),
    [data, error, loading],
  );
};
