import { useAppBridge } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useCallback, useMemo } from 'react';
import type {
  Section,
  RemotePayload,
  AdminPathPayload,
  AdminSectionPayload,
} from '@shopify/app-bridge/actions/Navigation/Redirect';

export function useAppBridgeRedirect() {
  const app = useAppBridge();
  const redirect = Redirect.create(app);
  const { ADMIN_PATH, ADMIN_SECTION, REMOTE, APP } = Redirect.Action;

  const redirectRemote = useCallback(
    (url: string | RemotePayload) => {
      redirect.dispatch(REMOTE, url);
    },
    [REMOTE, redirect],
  );

  const redirectApp = useCallback(
    (url: string) => {
      redirect.dispatch(APP, url);
    },
    [APP, redirect],
  );

  const redirectAdmin = useCallback(
    (url: string | AdminPathPayload) => {
      redirect.dispatch(ADMIN_PATH, url);
    },
    [ADMIN_PATH, redirect],
  );

  const redirectSection = useCallback(
    (url: Section | AdminSectionPayload) => {
      redirect.dispatch(ADMIN_SECTION, url);
    },
    [ADMIN_SECTION, redirect],
  );

  return useMemo(
    () => ({
      app: redirectApp,
      admin: redirectAdmin,
      remote: redirectRemote,
      section: redirectSection,
    }),
    [redirectAdmin, redirectApp, redirectRemote, redirectSection],
  );
}
