import axios, { AxiosRequestConfig } from 'axios';
import { getSessionToken } from '@shopify/app-bridge-utils';

const axiosInstance = axios.create({
  baseURL: `${process.env.HOST}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async config => {
  if (typeof window === 'undefined') return config;
  if (!window.app) throw new Error('App is not initialized');

  const sessionToken = await getSessionToken(window.app);

  if (config?.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${sessionToken}`;
  }

  return config;
});

export async function fetcher(resource: string, options?: AxiosRequestConfig) {
  const { data } = await axiosInstance.request({
    method: 'GET',
    url: resource,
    ...options,
  });

  return data;
}

export default axiosInstance;
