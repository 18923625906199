import { useCallback, useEffect } from 'react';
import { Banner, Layout } from '@shopify/polaris';

import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';
import { ActionType, useAppContext } from '@contexts/app-context';
import { useIsPostPurchase } from '@hooks/useIsPostPurchase';

export const PostPurchaseBanner = () => {
  const redirect = useAppBridgeRedirect();
  const {
    dispatch,
    state: { hidePostPurchaseBanner, postPurchaseEnabled },
  } = useAppContext();
  const { data, isLoading } = useIsPostPurchase();

  const handleDismiss = useCallback(() => {
    dispatch({ type: ActionType.HIDE_POST_PURCHASE_BANNER, payload: true });
  }, [dispatch]);

  useEffect(() => {
    if (data?.isPostPurchaseAppInUse) {
      dispatch({
        type: ActionType.POST_PURCHASE_APP_ENABLED,
        payload: data?.isPostPurchaseAppInUse,
      });
      dispatch({
        type: ActionType.HIDE_POST_PURCHASE_BANNER,
        payload: data?.isPostPurchaseAppInUse,
      });
    }

    return () => {
      dispatch({
        type: ActionType.HIDE_POST_PURCHASE_BANNER,
        payload: postPurchaseEnabled,
      });
    };
  }, [data, dispatch, postPurchaseEnabled]);

  if (isLoading || postPurchaseEnabled || hidePostPurchaseBanner) return null;

  return (
    <Layout.Section>
      <Banner
        title="Action needed"
        status="warning"
        action={{
          content: 'Enable setting',
          onAction() {
            redirect.admin('/settings/checkout#PostPurchaseSection');
          },
        }}
        onDismiss={handleDismiss}
      >
        <p>
          Shopify requires enabling new checkout setting in order to show{' '}
          post-purchase promotions. Just select <strong>{data?.title}</strong>{' '}
          under the “Post-purchase page” section.
        </p>
      </Banner>
    </Layout.Section>
  );
};
