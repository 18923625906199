import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';

import { Grid, GridItem } from '@styles/Global';
import React from 'react';

export const MediaCardSkeleton = () => {
  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <div
          className="SkeletonShimmer"
          style={{
            flex: '0 0 30%',
            height: 144,
            borderTopLeftRadius: '0.8rem',
            borderBottomLeftRadius: '0.8rem',
          }}
        />
        <div style={{ flex: '0 0 70%', padding: '2rem' }}>
          <div style={{ marginBottom: '2rem' }}>
            <SkeletonDisplayText />
          </div>
          <SkeletonBodyText />
        </div>
      </div>
    </Card>
  );
};

export const MediaCardListSkeleton = () => {
  return (
    <Grid $lg={2} $xxl={3}>
      <Layout.Section fullWidth>
        <SkeletonDisplayText size="small" />
      </Layout.Section>
      {Array.from({ length: 2 }).map((_, index) => (
        <GridItem key={index}>
          <MediaCardSkeleton />
        </GridItem>
      ))}
    </Grid>
  );
};
