import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorsBanner } from '@components/Banner';
import {
  SidebarInner,
  SidebarHeader,
  SidebarWrapper,
} from '@components/Sidebar';
import { useConfirmModal } from '@components/ContextualSaveBar';
import { Tab, TabsNavigation } from './styles';

type TabsProps = {
  active?: number;
  tabs: string[];
  children: Array<React.ReactNode>;
};

export const Tabs = ({ tabs, children, active }: TabsProps) => {
  const [selected, setSelected] = useState(active ?? 0);
  const handleClick = useConfirmModal();
  const { reset, clearErrors } = useFormContext();

  const handleTabClick = (index: number) => {
    handleClick(() => {
      clearErrors();
      reset();
      setSelected(index);
    });
  };

  return (
    <SidebarWrapper>
      <ErrorsBanner />
      <SidebarInner className="Polaris-Card">
        <SidebarHeader>
          <TabsNavigation>
            {tabs.map((item, index) => (
              <Tab
                key={index}
                $active={selected === index}
                onClick={() => handleTabClick(index)}
              >
                {item}
              </Tab>
            ))}
          </TabsNavigation>
        </SidebarHeader>
        {React.Children.map(children, (child, index) =>
          selected === index ? child : null,
        )}
      </SidebarInner>
    </SidebarWrapper>
  );
};
