import {
  Stack,
  Button,
  Checkbox,
  TextStyle,
  Thumbnail,
} from '@shopify/polaris';
import {
  ImageMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from '@shopify/polaris-icons';
import { useCallback, useMemo, useState } from 'react';

import { isAutoRenewProduct } from '../helpers';
import type { Product } from '../types';
import {
  ProductWrapper,
  CheckboxWrapper,
  ProductLineGrid,
  ProductLineGridItem,
} from './styles';
import { ActionType, useProductsContext } from '../context';
import { ProductVariants } from './ProductVariants';

type ProductItemProps = {
  product: Product;
};

const ProductItem = ({ product }: ProductItemProps) => {
  const { state, dispatch } = useProductsContext();
  const [showVariants, setShowVariants] = useState(false);
  const { id: productId, title, images, variants, totalVariants } = product;

  const variantIds = useMemo(() => variants?.map(({ id }) => id), [variants]);
  const selected = useMemo(
    () => state.selected.find(({ id }: Product) => id === productId),
    [productId, state.selected],
  );

  const extendVariants = useMemo(
    () =>
      variants?.map(variant => ({
        ...variant,
        product,
      })),
    [product, variants],
  );

  const isSelectedAllVariants = useMemo(
    () => totalVariants === selected?.variantIds?.length,
    [selected, totalVariants],
  );

  let isChecked: boolean | 'indeterminate';

  if (selected && isSelectedAllVariants) {
    isChecked = true;
  } else if (selected && !isSelectedAllVariants) {
    isChecked = 'indeterminate';
  } else {
    isChecked = false;
  }

  const isDisabled =
    (state.selectDisabled && !isChecked) || isAutoRenewProduct(title);

  const handleChangeProduct = async (value: boolean) => {
    if (value && isChecked === 'indeterminate') {
      dispatch({
        type: ActionType.UPDATE_PRODUCT,
        payload: { ...product, variantIds },
      });
    } else if (value) {
      dispatch({
        type: ActionType.ADD_PRODUCT,
        payload: { ...product, variantIds },
      });
    } else {
      dispatch({
        type: ActionType.REMOVE_PRODUCT,
        payload: productId,
      });
    }
  };

  const toggleShowVariants = useCallback(() => {
    setShowVariants(show => !show);
  }, []);

  return (
    <Stack vertical>
      <ProductLineGrid>
        <ProductWrapper>
          <ProductLineGrid>
            <ProductLineGridItem>
              <CheckboxWrapper $hide={isDisabled}>
                <Checkbox
                  labelHidden
                  label="Select product"
                  checked={isChecked}
                  onChange={handleChangeProduct}
                />
              </CheckboxWrapper>
            </ProductLineGridItem>
            <ProductLineGridItem>
              <Thumbnail
                size="small"
                source={images.length ? images[0].src : ImageMajor}
                alt=""
              />
            </ProductLineGridItem>
            <ProductLineGridItem>
              <h3>
                <TextStyle variation="strong">{title}</TextStyle>
              </h3>
            </ProductLineGridItem>
          </ProductLineGrid>
          {totalVariants > 1 ? (
            <ProductLineGridItem>
              <Button
                plain
                size="slim"
                icon={showVariants ? ChevronUpMinor : ChevronDownMinor}
                onClick={toggleShowVariants}
              />
            </ProductLineGridItem>
          ) : null}
        </ProductWrapper>
      </ProductLineGrid>

      {showVariants ? (
        <Stack.Item>
          <ProductVariants
            disabled={isDisabled}
            selected={selected ? selected?.variantIds : []}
            variants={extendVariants}
          />
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export { ProductItem };
