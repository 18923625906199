import React, { isValidElement } from 'react';
import {
  Stack,
  Button,
  Layout,
  TextStyle,
  DisplayText,
} from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';

import { Navigation as MainNavigation } from '@components/Navigation';
import { AnnouncementBanner } from '@components/Banner/AnnouncementBanner';
import { useSubscriptionStatus } from '@hooks/useSubscriptionStatus';

interface PrimaryAction {
  content: string;
  loading?: boolean;
  disabled?: boolean;

  onAction?(): void;
}

type PageHeaderProps = {
  title?: string;
  breadcrumbs?: { url: string };
  navigation?: React.ReactNode;
  primaryAction?: PrimaryAction | React.ReactNode;
};

export function isPrimaryAction(
  x: PrimaryAction | React.ReactNode,
): x is PrimaryAction {
  return !isValidElement(x) && x !== undefined;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { title, breadcrumbs, navigation, primaryAction } = props;
  const { status, plan, confirmationUrl } = useSubscriptionStatus();

  const breadcrumbsMarkup = breadcrumbs && (
    <Button icon={ArrowLeftMinor} url={breadcrumbs.url} />
  );

  const actionMarkup = !isPrimaryAction(primaryAction) ? (
    primaryAction
  ) : (
    <Button
      primary
      disabled={primaryAction?.disabled}
      loading={primaryAction?.loading}
      onClick={primaryAction?.onAction}
    >
      {primaryAction?.content}
    </Button>
  );

  const navigationMarkup = (status.includes('subscription') && navigation) || (
    <MainNavigation />
  );

  const titleMarkup = title && (
    <DisplayText element="h1" size="small">
      <TextStyle variation="strong">{title}</TextStyle>
    </DisplayText>
  );

  return (
    <>
      <AnnouncementBanner
        status={status}
        plan={plan}
        confirmationUrl={confirmationUrl}
      />
      <Layout.Section fullWidth>
        <Stack alignment="center" distribution="equalSpacing">
          <Stack alignment="center">
            {breadcrumbsMarkup}
            {titleMarkup}
          </Stack>
          <Stack alignment="center" spacing="extraLoose">
            {navigationMarkup}
            {actionMarkup}
          </Stack>
        </Stack>
      </Layout.Section>
    </>
  );
};
