import React from 'react';
import { Stack } from '@shopify/polaris';

import { Heading, Description } from './components';
import { HeroBody, HeroWrapper } from './styles';

type HeroProps = {
  color: 'green' | 'red' | string;
  children: React.ReactNode;
};

export const Hero = ({ color = 'green', children }: HeroProps) => {
  return (
    <HeroWrapper color={color}>
      <HeroBody>
        <Stack vertical>
          {children}
        </Stack>
      </HeroBody>
    </HeroWrapper>
  );
};

Hero.Heading = Heading;
Hero.Description = Description;
