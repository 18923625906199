import { useCallback } from 'react';
import { Banner, Button, Layout, TextStyle } from '@shopify/polaris';

import type { Plan } from '@pages/billing/types';
import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';
import { ActionType, useAppContext } from '@contexts/app-context';

type AnnouncementBannerProps = {
  status: string[];
  plan: Plan | undefined;
  confirmationUrl: string | undefined;
};

export const AnnouncementBanner = (props: AnnouncementBannerProps) => {
  const { status, plan, confirmationUrl } = props;
  const {
    state: { showAffiliateBanner, showPendingBanner },
    dispatch,
  } = useAppContext();
  const redirect = useAppBridgeRedirect();

  const handleDismiss = (type: ActionType) => {
    dispatch({ type, payload: false });
  };

  const handleClick = useCallback(() => {
    redirect.remote(confirmationUrl || '/');
  }, [confirmationUrl, redirect]);

  if (!status.length || (!showAffiliateBanner && !showPendingBanner)) {
    return null;
  }

  return (
    <Layout.Section fullWidth>
      {status.includes('affiliate') && showAffiliateBanner ? (
        <Banner
          status="info"
          onDismiss={() => handleDismiss(ActionType.SHOW_AFFILIATE_BANNER)}
        >
          You are using free &quot;Affiliate&quot; plan (for development stores
          only).
        </Banner>
      ) : null}
      {status.includes('pending') && showPendingBanner ? (
        <Banner
          status="warning"
          onDismiss={() => handleDismiss(ActionType.SHOW_PENDING_BANNER)}
        >
          <span>
            Your <TextStyle variation="strong">{plan?.name}</TextStyle> plan is
            pending.{' '}
            <Button plain monochrome onClick={handleClick}>
              Approve payment
            </Button>{' '}
            to activate the new plan.
          </span>
        </Banner>
      ) : null}
    </Layout.Section>
  );
};
