import styled from 'styled-components';

export const ColorPoint = styled.div<{ $currentColor: string }>`
  width: 100%;
  height: 36px;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 3px;
  background-color: ${(p): string => p.$currentColor};
  margin-top: -12px;
`;
