import styled from 'styled-components';

export const MediaCardInner = styled.div<{ $vertical?: boolean }>`
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: ${p => (p.$vertical ? 'column' : 'row')};
  }
`;

export const MediaCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  padding: 2rem;
`;

export const MediaCardImage = styled.div<{
  $image?: string | null;
  $vertical?: boolean;
}>`
  position: relative;
  width: 100%;
  aspect-ratio: ${p => (p.$vertical ? '1 / 0.78' : '1 / 1')};
  border-radius: 0.8rem 0.8rem 0 0;
  background-color: ${p => (p.$image ? 'transparent' : 'rgba(0 0 0 / 10%)')};
  overflow: hidden;

  @media (min-width: 768px) {
    width: ${p => (p.$vertical ? '100%' : '144px')};
    border-radius: ${p =>
      // eslint-disable-next-line
      p.$vertical ? '0.8rem 0.8rem 0 0' : '0.8rem 0 0 0.8rem'};
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const MediaCardHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) auto;
  align-items: center;
  width: 100%;
`;

export const MediaCardDropdown = styled.div`
  align-self: flex-start;
  margin-left: 20px;
`;
