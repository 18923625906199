import { useCallback, useMemo, useEffect } from 'react';
import {
  TextField,
  ChoiceList,
  Filters as PolarisFilters,
} from '@shopify/polaris';

import { useProductsFilters } from '../hooks/useProductsFilter';
import { useCollections } from '../hooks/useCollections';

type ProductsFilterProps = {
  onQueryChange(value: string): void;
  onTagChange(value: string): void;
  onCollectionChange(id: string): void;
};

export const ProductsFilters = (props: ProductsFilterProps) => {
  const { onTagChange, onQueryChange, onCollectionChange } = props;
  const { query, setQuery, tag, setTag, collection, setCollection } =
    useProductsFilters();
  const { data: collections } = useCollections();

  const choices = useMemo(() => {
    return collections.map(({ id, title }) => ({ label: title, value: id }));
  }, [collections]);

  const filters = useMemo(
    () => [
      {
        key: 'collection',
        label: 'Collection',
        disabled: Boolean(tag),
        filter: (
          <ChoiceList
            titleHidden
            title="Collections"
            disabled={Boolean(tag)}
            selected={collection}
            onChange={setCollection}
            choices={choices}
          />
        ),
      },
      {
        key: 'tag',
        label: 'Tagged with',
        disabled: Boolean(collection.length),
        filter: (
          <TextField
            labelHidden
            label="Tagged with"
            disabled={Boolean(collection.length)}
            value={tag}
            onChange={setTag}
            autoComplete="off"
          />
        ),
      },
    ],
    [tag, collection, setCollection, choices, setTag],
  );

  const appliedFilters = [];

  if (tag) {
    appliedFilters[0] = {
      key: 'tag',
      label: `Tagged with ${tag}`,
      onRemove: () => setTag(''),
    };
  }

  if (collection.length) {
    const selectedCollection = choices.find(
      ({ value }) => value === collection[0],
    );

    appliedFilters[0] = {
      key: 'collection',
      label: `Collection is ${selectedCollection?.label || ''}`,
      onRemove: () => setCollection([]),
    };
  }

  const handleClearAll = useCallback(() => {
    setTag('');
    setQuery('');
    setCollection([]);
  }, [setTag, setQuery, setCollection]);

  useEffect(() => {
    onTagChange(tag);
    onQueryChange(query);
    onCollectionChange(collection[0] ?? null);
  }, [tag, query, collection, onTagChange, onQueryChange, onCollectionChange]);

  return (
    <PolarisFilters
      queryValue={query}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setQuery}
      onQueryClear={() => setQuery('')}
      onClearAll={handleClearAll}
    />
  );
};
