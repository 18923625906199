import { gql } from '@apollo/client';

import {
  PAGE_INFO,
  PRODUCT_IMAGES,
  PRODUCT_OPTIONS,
  PRODUCT_VARIANTS,
} from './fragments';

export const GET_COLLECTION_PRODUCTS_QUERY = gql`
  ${PAGE_INFO}
  ${PRODUCT_IMAGES}
  ${PRODUCT_VARIANTS}
  ${PRODUCT_OPTIONS}
  query getCollectionProducts($id: ID!, $first: Int, $after: String) {
    collection(id: $id) {
      products(first: $first, after: $after, sortKey: TITLE) {
        ...PageInfo
        edges {
          cursor
          node {
            id
            title
            handle
            totalVariants
            descriptionHtml
            tags
            ...Variants
            ...Options
            ...Images
          }
        }
      }
    }
  }
`;
