import React from 'react';
import { Heading } from '@shopify/polaris';

import { LineClamp } from '@styles/Global';

type TitleProps = {
  children: React.ReactNode;
};

export const Title = ({ children }: TitleProps) => {
  return (
    <Heading element="h2">
      <LineClamp>{children}</LineClamp>
    </Heading>
  );
};
