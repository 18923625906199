import React from 'react';
import { TextStyle } from '@shopify/polaris';

import { Legend } from './styles';

type FieldsetProps = {
  label: string;
  variation?: 'positive' | 'negative' | 'strong' | 'subdued' | 'code';
  children: React.ReactNode;
};

export const Fieldset = ({ label, variation, children }: FieldsetProps) => {
  return (
    <>
      <Legend>
        <TextStyle variation={variation}>{label}</TextStyle>
      </Legend>
      {children}
    </>
  );
};
