import styled from 'styled-components';
import { colorSkyLighter } from '@shopify/polaris-tokens';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  align-items: start;
  border-radius: 4px;
  padding: 15px;
  background-color: ${colorSkyLighter};

  .Polaris-Button__Content {
    font-size: 12px;
  }
`;

export const Content = styled.span`
  font-size: 12px;
`;

export const IconHolder = styled.span<{ $color?: string }>`
  .Polaris-Icon {
    fill: ${p => p.$color ?? '#ffb800'};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    flex: 1 0;
  }
`;
