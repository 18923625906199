import React from 'react';
import {
  Button,
  Card,
  DisplayText,
  Stack,
  ButtonGroup,
  Scrollable,
  Backdrop,
} from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';

import { ModalDialog, ModalContainer, ModalBodyWrapper } from './styles';

interface PrimaryActions {
  content?: string;
  destructive?: boolean;
  disabled?: boolean;
  loading?: boolean;

  onAction?(): void;
}

type ModalProps = {
  title: string;
  open: boolean;
  onClose(): void;
  primaryActions?: PrimaryActions;
  secondaryActions?: PrimaryActions;
  headerActions?: React.ReactNode;
  children?: React.ReactNode;
};

export const Modal = (props: ModalProps) => {
  const {
    title,
    open,
    onClose,
    primaryActions,
    headerActions,
    secondaryActions,
    children,
  } = props;

  if (!open) return null;

  return (
    <div>
      <ModalContainer>
        <ModalDialog>
          <Card>
            <Card.Section>
              <Stack alignment="center" distribution="equalSpacing">
                <DisplayText size="small">{title}</DisplayText>
                <Stack alignment="center" spacing="extraLoose">
                  {headerActions}
                  <Button plain icon={MobileCancelMajor} onClick={onClose} />
                </Stack>
              </Stack>
            </Card.Section>
            <Card.Section>
              <ModalBodyWrapper>
                <Scrollable style={{ height: 'calc(100vh - 206px)' }}>
                  {children}
                </Scrollable>
              </ModalBodyWrapper>
            </Card.Section>
            {primaryActions && (
              <Card.Section>
                <Stack>
                  <Stack.Item fill />
                  <ButtonGroup>
                    {secondaryActions && (
                      <Button onClick={secondaryActions.onAction}>
                        {secondaryActions.content}
                      </Button>
                    )}
                    {primaryActions && (
                      <Button
                        primary
                        loading={primaryActions.loading}
                        onClick={primaryActions.onAction}
                      >
                        {primaryActions.content}
                      </Button>
                    )}
                  </ButtonGroup>
                </Stack>
              </Card.Section>
            )}
          </Card>
        </ModalDialog>
      </ModalContainer>
      <Backdrop />
    </div>
  );
};
