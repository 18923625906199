import styled from 'styled-components'

export const Title = styled.div`
  width: 200px;
`

export const Body = styled.div`
  display: flex;
  margin: 20px 0 0;
  align-items: center;
`

export const Text = styled.div`
  width: 150px;
  margin-left: 20px;
`
