import {
  ActionType,
  useContextualSaveBarContext,
} from '@components/ContextualSaveBar/contextual-save-bar-context';

export function useConfirmModal() {
  const { state, dispatch } = useContextualSaveBarContext();

  return (callback: () => void) => {
    if (state.isUnsavedChanges) {
      dispatch({ type: ActionType.OPEN_CONFIRM_MODAL, payload: callback });
    } else {
      callback();
    }
  };
}
