import { useMemo } from 'react';

export function useBuildSearchQuery(
  queryValue: string | null,
  tagValue: string | null,
) {
  return useMemo(() => {
    const query: Record<string, string> = {};

    if (tagValue) {
      query.tag = `tag:*${tagValue}*`;
    } else {
      delete query.tag;
    }

    if (queryValue) {
      query.title = `title:*${queryValue}*`;
    } else {
      delete query.title;
    }

    return Object.values(query).join(' ');
  }, [queryValue, tagValue]);
}
