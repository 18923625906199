import { useMemo, useState } from 'react';

function useSearch() {
  const [query, setQuery] = useState('');
  return useMemo(() => ({ query, setQuery }), [query, setQuery]);
}

function useTag() {
  const [tag, setTag] = useState('');
  return useMemo(() => ({ tag, setTag }), [tag, setTag]);
}

function useCollection() {
  const [collection, setCollection] = useState<string[] | []>([]);
  return useMemo(
    () => ({
      collection,
      setCollection,
    }),
    [collection, setCollection],
  );
}

export function useProductsFilters() {
  const { collection, setCollection } = useCollection();
  const { query, setQuery } = useSearch();
  const { tag, setTag } = useTag();

  return useMemo(
    () => ({
      tag,
      query,
      collection,
      setTag,
      setQuery,
      setCollection,
    }),
    [query, tag, collection, setCollection, setTag, setQuery],
  );
}
