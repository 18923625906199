import { Modal } from '@shopify/app-bridge-react';
import { useRouter } from 'next/router';

import {
  ActionType,
  useContextualSaveBarContext,
} from './contextual-save-bar-context';

export const ConfirmModal = () => {
  const router = useRouter();
  const { state, dispatch } = useContextualSaveBarContext();

  return (
    <Modal
      title="You have unsaved changes"
      message="If you leave this page, all unsaved changes will be lost."
      open={state.openConfirmModal}
      primaryAction={{
        destructive: true,
        content: 'Leave page',
        async onAction() {
          dispatch({ type: ActionType.LEAVE_PAGE, payload: null });
          if (typeof state?.route === 'string') await router.push(state.route);
          if (typeof state?.route === 'function') state.route();
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction() {
            dispatch({ type: ActionType.CLOSE_CONFIRM_MODAL, payload: null });
          },
        },
      ]}
      onClose={() =>
        dispatch({
          type: ActionType.CLOSE_CONFIRM_MODAL,
          payload: null,
        })
      }
    />
  );
};
