export function transformImageUrl(
  url: string,
  width?: number | string,
  height?: number | string,
) {
  if (!url || !/(cdn\.shopify\.com)/.test(url)) return url;

  const pattern = new RegExp(/\.(png|jpg|jpeg|gif)/);
  const extension = url.match(pattern)?.[0];

  if (!extension || (!width && !height)) return url;

  return url.replace(pattern, `_${width ?? ''}x${height ?? ''}${extension}`);
}
