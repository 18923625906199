import { ActionList, Button, Popover } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import React, { useCallback, useState } from 'react';

import type { ActionListItemDescriptor } from '@shopify/polaris';

type DropdownProps = {
  isPrimary?: boolean;
  buttonLabel?: string;
  popoverActions: ActionListItemDescriptor[];
  preferredAlignment?: 'left' | 'center' | 'right';
};

export const Dropdown = (props: DropdownProps) => {
  const {
    buttonLabel,
    popoverActions,
    preferredAlignment = 'right',
    isPrimary = true,
  } = props;
  const [active, setActive] = useState(false);

  const handleClose = useCallback(() => setActive(false), []);

  const handleToggle = useCallback(
    () => setActive(popoverActive => !popoverActive),
    [],
  );

  const activatorMarkup = buttonLabel ? (
    <Button primary={isPrimary} onClick={handleToggle}>
      {buttonLabel}
    </Button>
  ) : (
    <Button
      plain
      size="slim"
      icon={HorizontalDotsMinor}
      onClick={handleToggle}
    />
  );

  return (
    <Popover
      preferredAlignment={preferredAlignment}
      active={active}
      activator={activatorMarkup}
      onClose={handleClose}
    >
      <ActionList items={popoverActions} />
    </Popover>
  );
};
