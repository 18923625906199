import { useCallback, useMemo } from 'react';
import { Modal as PolarisModal } from '@shopify/polaris';

import { outputProductsAdapter } from '@components/ProductsPicker/helpers';
import { Products } from './Products';
import { ActionType, useProductsContext } from '../context';

type ProductsContainerProps = {
  open: boolean;
  onCancel(): void;
  onSelection(selectPayload: any): void;
};

export const ProductContainer = (props: ProductsContainerProps) => {
  const { open, onCancel, onSelection } = props;
  const {
    state: { selected },
    dispatch,
  } = useProductsContext();

  const handleClose = useCallback(() => {
    dispatch({ type: ActionType.CLEAR, payload: null });
    onCancel();
  }, [dispatch, onCancel]);

  const primaryAction = useMemo(
    () => ({
      content: 'Apply',
      disabled: !selected.length,
      onAction() {
        onSelection(outputProductsAdapter(selected));
        dispatch({ type: ActionType.CLEAR, payload: null });
        onCancel();
      },
    }),
    [dispatch, onCancel, onSelection, selected],
  );

  const secondaryActions = useMemo(
    () => [
      {
        content: 'Close',
        onAction: handleClose,
      },
    ],
    [handleClose],
  );

  const handleScrolledBottom = useCallback(() => {
    dispatch({ type: ActionType.SCROLLED_BOTTOM, payload: true });
  }, [dispatch]);

  return (
    <PolarisModal
      title="Add product"
      limitHeight
      open={open}
      onClose={handleClose}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      onScrolledToBottom={handleScrolledBottom}
    >
      <PolarisModal.Section>
        <Products />
      </PolarisModal.Section>
    </PolarisModal>
  );
};
