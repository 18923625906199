import { useAppBridge } from '@shopify/app-bridge-react';
import { Toast } from '@shopify/app-bridge/actions';
import { useCallback, useMemo } from 'react';

interface ToastOptions {
  message: string;
  duration?: number;
  isError?: boolean;
}

export function useToast() {
  const app = useAppBridge();
  const toastOptions = useMemo(
    () => ({
      duration: 5000,
      isError: false,
    }),
    [],
  );

  return useCallback(
    (options: ToastOptions) => {
      const toast = Toast.create(app, { ...toastOptions, ...options });
      toast.dispatch(Toast.Action.SHOW);
    },
    [app, toastOptions],
  );
}
