import { gql } from '@apollo/client';

export const GET_PRODUCTS_BY_IDS_QUERY = gql`
  query getProductsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
      }
    }
  }
`;
