import styled from 'styled-components';
import { Badge, Tag, TextStyle } from '@shopify/polaris';

import { ActionType, useProductsContext } from '../context';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 30;
  margin: 0 -2rem;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
`;

export const TagWrapper = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
`;

function truncateProductTitle(title: string) {
  if (!title) return '';

  if (title?.trim().length > 30) {
    return `${title.slice(0, 30).trim()}...`;
  }

  return title;
}

export const SelectedProductList = () => {
  const {
    state: { selected, selectMultiple, maxSelectedProducts },
    dispatch,
  } = useProductsContext();
  const leftProducts = `${
    (selectMultiple ? maxSelectedProducts : 1) - selected.length
  }`;

  const handleRemoveProduct = (id: number) => {
    dispatch({
      type: ActionType.REMOVE_PRODUCT,
      payload: id,
    });
  };

  return (
    <Wrapper>
      <TextStyle>
        You can pick <Badge status="info">{leftProducts}</Badge> more products
      </TextStyle>
      {': '}
      {selected?.map(({ id, title }: any) => (
        <TagWrapper key={id}>
          <Tag onRemove={() => handleRemoveProduct(id)}>
            {truncateProductTitle(title)}
          </Tag>
        </TagWrapper>
      ))}
    </Wrapper>
  );
};
